// Font path needs to be relative to this file. See: https://github.com/parcel-bundler/parcel/issues/6285
$mdi-font-path:   'npm:@mdi/font/fonts/' !default;

@import 'npm:@mdi/font/scss/materialdesignicons';

.mdi::before {
  letter-spacing: 0;
  font-weight: inherit;
}

// Alert

.alert.mdi::before {
  font-size: 1.375em;
  line-height: 1;
  vertical-align: bottom;
  margin-right: .5rem;
}

// Button

.btn {
  &.mdi::before,
  .mdi::before {
    font-size: 1.5em;
    line-height: 1;
    vertical-align: bottom;
  }
}

// Collapse

.mdi.icon-collapse {
  @extend .mdi-chevron-up;
}

.collapsed {
  &.mdi,
  & .mdi {
    &.icon-collapse {
      @extend .mdi-chevron-down;
    }
  }
}

// List Group

.list-group-item.mdi::before {
  font-size: 1.375em;
  line-height: 1;
  vertical-align: bottom;
  margin-right: .5rem;
}

a.list-group-item.mdi::before {
  font-size: 1.5em;
}
